import styled from 'styled-components'

const RegContent = styled.div`
  width: 80%;
  color: var(--textColor);
  margin: 0 auto;
  transform: skewY(2deg);
`

export default RegContent
