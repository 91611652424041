import React from 'react'

import Layout from '../components/Layout'
import FunkyTitle from '../components/FunkyTitle'
import List from '../css/List'
import RegContent from '../css/RegContent'
import Section from '../css/Section'

const pageTitle = 'Stuff I Use'

function UsesPage(props) {
  return (
    <>
      <FunkyTitle title={pageTitle} />
      <Section>
        <RegContent>
          <List>
            <li>VS Code</li>
            {/* Link to vscode extensions blog post */}
            {/* iTerm 2 ZSH with ...
            raindrop stand
            Apps I like */}
            Technologies -
            <li>Node.js</li>
            <li>React.js</li>
            <li>PostgreSQL</li>
            Stuff -
            <li>Pok3r Mechanical Keyboard</li>
            <li>MX Master 2S</li>
            <li>Timbuk2 messenger bag</li>
            Hardware -
            <li>MacBook Pro 15</li>
            <li>Audio Technica SR6</li>
          </List>
        </RegContent>
      </Section>
    </>
  )
}

UsesPage.Layout = Layout
UsesPage.pageTitle = pageTitle

export default UsesPage
